import React, { useEffect, useState } from 'react'
import getProducts from '../helper/api/getProducts'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import {FaPlaneDeparture, FaPlaneArrival} from 'react-icons/fa'
export default function PageUntils () {





    const [Product, setProduct] = useState(null);
    const [Product2, setProduct2] = useState(null);
    const [url1, setUrl1] = useState();
    const [url2, setUrl2] = useState();
    const [CategoryValues, setCategory] = useState(null);
    const [CategoryMore, setCategoryMore] = useState(false);
    const [SelectCategory, setSelectCategory] = useState(null);
       const submitForm = (e) => {
           e.preventDefault()
           const Promises = async function () {
               const result = await getProducts(url1);

               let Category = [];

                result[0].map((element) => {
                const query = Category.find(e => e.name  == element.category);
                    if(!query){
                    
                        Category.push({name: element.category, point: 1})

                       
                        setCategory(Category);
                    
                    }if(query){
                        
                        const val = Category.findIndex(e => e.name  == element.category)
                        
                        Category[val].point =  Category[val].point + 1;

                        setCategory(Category);
                    }
                })
               const a =  Category.sort(function(a, b) {
                    return parseFloat(a.point) - parseFloat(b.point);
                })
                
               setCategory(a.reverse());  
               
               setProduct(result[0]);
           }
           const Promises2 = async function () {
               const result = await getProducts(url2);
               setProduct2(result[0]);
           }
           if(url2)Promises2();
           if(url1)Promises();
       }

       useEffect(( ) => {

       }, [Product,CategoryValues])



       useEffect(( ) => {
       }, [url1, url2])

       const productFinder = (title) => {
        let b = {};
         if(Product2){
             b = Product2?.find((elem) => elem.Title == title);
             return  b;
            }
       }

       const PriceBox = ({Departure , Arrival}) => {
        const departurePrice = Departure.price?.trim().split(" ");
        const arrivalPrice = Departure.price?.trim().split(" ");
        return (
            <div className="top-offers p-0 m-0">
                                    <a className="s14oa9nh-0 lihtyI" href={Departure?.productUrl} rel="nofollow noopener" target="_blank">
                                            <div className="tag mb-2 mt-2"><FaPlaneDeparture /> {url1}</div> {departurePrice[8] ? <strike> {departurePrice[0]}</strike>: departurePrice[0]}  <span style={{color: 'red'}}> {departurePrice[8]}</span>
                                            <p className='roundelText'> <span style={{fontSize: 12}}>{Departure?.roundelText}</span></p>
                                    </a>
    
                                    <hr />
                                        <a className="s14oa9nh-0 lihtyI" href={Arrival?.productUrl} rel="nofollow noopener" target="_blank"><div className="tag"><FaPlaneArrival /> {url2} 
                                          </div>{(Arrival?.price == undefined) ?
                                            <p><span className="text-danger" style={{fontSize: 12}}>Bu hava alanın'da ürün mevcut değil</span></p>
                                           :
                                           Departure.price.trim().split(" ")[8] ? <strike> {arrivalPrice[0]}</strike>: arrivalPrice[0]}  <span style={{color: 'red'}}> {arrivalPrice[8]}</span>  
                                        <p className='roundelText'><span style={{fontSize: 12}}>{Arrival?.roundelText}</span></p>
                                        </a>
                                    </div>
        )
       }
       
return (
    <div className="container">
    <div className="row mb-2">
    <div className="col-lg-3 p-3">
    <h1>Logo</h1>
    </div>
  
    <div className="col-lg-9 ">
 
    </div>
    </div>

    <div className="row">
        <div className="col-lg-3">
            <h3>Brands</h3>
                <ListGroup as="ul" className="categoryListBox" >
                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start categoryList"  onClick={() => setSelectCategory(null) }>
                                             <div className="ms-2 me-auto"><div className="fw-bold">All Products</div></div>
                                 </ListGroup.Item>
                    {
                        CategoryValues?.map((items, key) =>{ 
                            if(CategoryMore == false ){
                                if(key <= 5){
                                    return (
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start categoryList" key={key} onClick={() => setSelectCategory(items.name) }>
                                                    <div className="ms-2 me-auto"><div className="fw-bold">{items.name}</div></div>
                                                    <Badge bg="primary">
                                                    {items.point}
                                                    </Badge>
                                        </ListGroup.Item>
                                    )
                                }
                                }
                            else{
                                 return (
                                 <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start categoryList" key={key} onClick={() => setSelectCategory(items.name) }>
                                             <div className="ms-2 me-auto"><div className="fw-bold">{items.name}</div></div>
                                             <Badge bg="primary" >
                                            {items.point}
                                             </Badge>
                                 </ListGroup.Item>
                                 )
                            }
                      })
                    }

        </ListGroup>

        <ListGroup as="ul" >
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" onClick={() => setCategoryMore(CategoryMore ? false : true)}>
                                <div className="ms-2 me-auto"><div className="">More...</div></div>
            </ListGroup.Item>
            </ListGroup>

        </div>
        <div className="col-lg-9">

        <form>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label" >Departure</label>
                            <input type="text"  className="form-control"  onChange={(e) => setUrl1(e.target.value)}/>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">Arrival</label>
                            <input type="text" className="form-control" id="url2"  onChange={(e) => setUrl2(e.target.value)}/>
                        </div>
                    </div>
                  <button type="submit" className="btn btn-primary w-100" onClick={(e) => submitForm(e)}>Submit</button>
        </form>
            <div className="row pt-3" style={{justifyContent: "center"}}>
            {
            
            Product?.map((item, idx) => {
                const getValues = productFinder(item.Title);
                if(!SelectCategory || SelectCategory == null){
                    return (
                        <div className='col-6 col-sm-6 col-md-4 col-lg-3 mb-3' key={idx}>
                                <div className='productBox p-2'>
                                <div className='ImageBox'><img src={item.Image} width="100%" /> </div>
                            <div className='BrandName' style={{fontSize: 16,fontWeight: 700}}><span> {item.category} </span></div>
                            <div className='productTitle' style={{fontSize: 13, fontWeight: 500}}><span>{item.Title}</span></div>
                            <PriceBox Departure={{"productUrl": item?.productUrl, "roundelText": item?.roundelText, "price": item.price}} Arrival={{"productUrl": getValues?.productUrl, "roundelText": getValues?.roundelText, "price": getValues?.price}}  />

                                    </div>
                        </div>
                        )
                }else{

                    if(SelectCategory == item.category){
                        return (
                            <div className='col-6 col-sm-6 col-md-4 col-lg-3 mb-3' key={idx}>
                                    <div className='productBox p-2'>
                                    <div className='ImageBox'><img src={item.Image} width="100%" /> </div>
                                <div className='BrandName' style={{fontSize: 16,fontWeight: 700}}><span> {item.category} </span></div>
                                <div className='productTitle' style={{fontSize: 13, fontWeight: 500}}><span>{item.Title}</span></div>
                                <PriceBox Departure={{"productUrl": item?.productUrl, "roundelText": item?.roundelText, "price": item.price}} Arrival={{"productUrl": getValues?.productUrl, "roundelText": getValues?.roundelText, "price": getValues?.price}}  />
                                        </div>
                            </div>
                            )
                    }
                }
            
            })
            }    
            </div>
        </div>
    </div>
    </div>
)

}
