import { render } from "react-dom";

import {BrowserRouter,Routes,Route,} from "react-router-dom";

import PageUntils from "./pages/untils";

// import your route components too

function App() {
  return (
      <BrowserRouter>
        <PageUntils />
      </BrowserRouter>
  );
}

export default App;
