import axios from 'axios';
export default async (companyName) => {
    try{
        const result =  [];  
           await axios({url: `https://shopdf.kreatifmerkezi.com/backend/api/v1/userShopProducts/${companyName}`, method: "get"})
              .then(function (response) {
                result.push(response.data)
                return response.data
              })
              .catch(function (err) {
                return err
              });
              return result;
    }catch{
        
    }

}